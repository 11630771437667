

















import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    Card: async () => import("@/components/Card.vue"),
  },
})
export default class Home extends Vue {
  public name = "Vladyslav Bogoslavets";
}
